import React, { useEffect, useState } from 'react'

import SideBar from '../../component/sidebar/SideBar'
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { apilink, path } from '../../data/fdata';
import axios from 'axios';
import { useAlert } from 'react-alert';
import { CircularProgress } from '@mui/material';
import CKeditorCom from '../course/CKeditorCom';
import parse from "html-react-parser";

const FeesOption = () => {

    const [pageloading, setPageLoading] = useState(false);
    const token = Cookies.get('_edushark_admin_access_token');
    const his = useHistory();
    const alert=useAlert()
    const [loading,setLoading] =useState(false)

const [feesCourse,setFeesCourse] =useState("")
const [feesAmount,setFeesAmount] =useState("")
const [feesTypeAction,setFeesTypeAction] =useState("")
const [feesTypeText,setFeesTypeText] =useState("")

const [sessionYear,setSessionYear] =useState("")


const [feesList,setFeesList] = useState([])
const [sessionList,setSessionList] = useState([])

const [regEditId,setRegEditId]=useState("")
const [isregEdit,setIsRegEdit]=useState(false)




useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            if(!res.data.userInfo?.isAdmin)
            {
              window.location.href = '/login';
            }
  
          }
          setTimeout(() => {
            setPageLoading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);


  const getFeestructs=async()=>{
    const res=await axios.get(`${apilink}/api/feestruct/getFeestructs`)
    // console.log(res.data)
    if(res.data.success){
        setFeesList(res.data.result)

    }else{
        alert.error(res.data.msg)
    }
  }

  const getAllSessions=async()=>{
    

    const res=await axios.get(`${apilink}/api/feestruct/getSessions`)
    // console.log(res.data)
    if(res.data.success){
        setSessionList(res.data.result)

    }else{
        alert.error(res.data.msg)
    }
  }

const submitFees=async(e)=>{
    e.preventDefault()
    setLoading(true)
    if(isregEdit)
    {
      let data={
        regEditId,
        feesTypeAction,
        feesCourse,
        feesAmount,
        feesTypeText
    }
    // api/feestruct

    const res=await axios.post(`${apilink}/api/feestruct/updateFeestruct`,data,{
        headers: {
          
          Authorization: token,
        },
      })
      if(res.data.success)
      {
        
        alert.success(res.data.msg)
       setFeesAmount("")
       setFeesCourse("")
    setIsRegEdit(false)
    setRegEditId("")
       setFeesTypeText("")
       setFeesTypeAction("")
        getFeestructs()
      
      }else{
        alert.error(res.data.msg)
  
      }

    }else{

      let data={
        feesTypeAction,
        feesCourse,
        feesAmount,
        feesTypeText
    }
    // api/feestruct

    const res=await axios.post(`${apilink}/api/feestruct/postFeestruct`,data,{
        headers: {
          
          Authorization: token,
        },
      })
      if(res.data.success)
      {
        
        alert.success(res.data.msg)
       setFeesAmount("")
       setFeesCourse("")
    setIsRegEdit(false)
    setRegEditId("")
       setFeesTypeText("")
       setFeesTypeAction("")
        getFeestructs()
      
      }else{
        alert.error(res.data.msg)
  
      }

    }

    
       

          setLoading(false)
}
const deleteFeestruct=async(feeid)=>{
    const res=await axios.get(`${apilink}/api/feestruct/deleteFeestruct/${feeid}`,{
        headers: {
    
            Authorization: token,
          },
    })
    // console.log(res.data)
    if(res.data.success)
    {
        alert.success(res.data.msg)
        let ar=feesList.filter((v)=>v._id != feeid)
        setFeesList(ar)
    }else{
        alert.error(res.data.msg)
    }
}
const deleteSessionApp=async(sesid)=>{
  

  const res=await axios.get(`${apilink}/api/feestruct/deleteSessionApp/${sesid}`,{
    headers: {

        Authorization: token,
      },
})
// console.log(res.data)
if(res.data.success)
{
    alert.success(res.data.msg)
    let ar=sessionList.filter((v)=>v._id != sesid)
    setSessionList(ar)
}else{
    alert.error(res.data.msg)
}
}
const submitSession=async(e)=>{
    e.preventDefault()
    setLoading(true)
        let data={
            session_app:sessionYear
        }
        // api/feestruct

        const res=await axios.post(`${apilink}/api/feestruct/postSessionApp`,data,{
            headers: {
              
              Authorization: token,
            },
          })
          if(res.data.success)
          {
            
            alert.success(res.data.msg)
           setSessionYear("")
          
            getAllSessions()
          
          }else{
            alert.error(res.data.msg)
      
          }

          setLoading(false)
}
useEffect(()=>{
    getFeestructs()
    getAllSessions()
  },[])

  return (
    <>
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 mb-2">
                <button className='sm_btn' data-toggle="modal"
                            data-target='#myModalses' >Add Session</button>
                             <div class="modal fade" id='myModalses'>
        <div class="modal-dialog modal modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Add Session</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
                <form onSubmit={submitSession}>

                
                     <div className="form-group ">
                       <label for="address">Session Year:</label>
                       <input
                         type="text"
                         placeholder="Enter Session Year"
                         className="form-control form_h"
                         name="sessionYear"
                         value={sessionYear}
                         onChange={(e) => setSessionYear(e.target.value)}
                         required
                       />
                     </div>

                     
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}

                </form>
             
            
            </div>
          </div>
        </div>
      </div>
                </div>
                <div className="col-12 mb-5">
                  <div className=" ">
                 
                  <div class="table-responsive  ">
          <table class="table table-borderless dash">
            <thead>
              <tr>
                <th className="w_60">Session Year</th>
                
                <th className='sm_w'>Operation</th>

               
                

                
              </tr>
            </thead>
            <tbody className=''>
              {
                sessionList.length > 0 ?(
                    <>
                    {
                sessionList?.map((val,ind)=>{
                    return(
                        <>
                        <tr key={ind}>
                <td className='w_60'>{val.session_app}</td>
                
                <td className='sm_W'> 
                {
                  sessionList.length > 1 && <i className="fa fa-trash text-danger cur" onClick={()=>deleteSessionApp(val._id)}></i>
                }
                

                

                
                 </td>

              </tr>
                        </>
                    )
                })
              }
                    
                    </>
                ):(
                    <>
                    <tr>
                        <td colSpan={2}>

<div className="text-center">
<p className="m-0">No Data Found</p>
</div>

                        </td>
                    </tr>
                    </>
                )
              }
             
            </tbody>
          </table>
        </div>

                </div>
                  </div>
                <div className="col-12">
                <div className="dd_flex">
                        <h3 className='text-custom'>Registration Fees</h3>
                        <button className='sm_btn' data-toggle="modal"
                            data-target='#myModal' onClick={()=>setFeesTypeAction("Registration")}>Registration Fees</button>

                        <div class="modal fade" id='myModal'>
        <div class="modal-dialog modal modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title"> {isregEdit ? "Edit": "Add"} Fees</h4>
              <button type="button" class="close" data-dismiss="modal" onClick={()=>{
                setFeesAmount("")
                setFeesCourse("")
             setIsRegEdit(false)
             setRegEditId("")
                setFeesTypeText("")
                setFeesTypeAction("")
              }}>
                &times;
              </button>
            </div>

            <div class="modal-body">
                <form onSubmit={submitFees}>

                <div className="form-group ">
                       <label for="address">Fees Course:</label>
                       <select
                          class="form-control form_h"
                          value={feesCourse}
                          onChange={(e) => setFeesCourse(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose--
                          </option>
                          <option value="BED">BED</option>
                          <option value="DELED">DELED</option>
                          <option value="DPHARM">DPHARM</option>

                        
                        </select>
                     </div>
                     <div className="form-group ">
                       <label for="address">Fees Amount:</label>
                       <input
                         type="number"
                         placeholder="Enter Fees Amount "
                         className="form-control form_h"
                         name="adfeesAmount"
                         value={feesAmount}
                         onChange={(e) => setFeesAmount(e.target.value)}
                         required
                       />
                     </div>

                     
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        {isregEdit ? "Save Data": "Submit Data"}
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}

                </form>
             
            
            </div>
          </div>
        </div>
      </div>
                    </div>
                  
                </div>
                <div className="col-12">
                  <div className=" mt-5">
                 
                  <div class="table-responsive  ">
          <table class="table table-borderless dash">
            <thead>
              <tr>
                <th className="w_20">Fees Type</th>
                <th className="sm_w">Fees Course</th>
                <th className="sm_w">Fees Amount</th>
              
                <th className='sm_w'>Operation</th>

               
                

                
              </tr>
            </thead>
            <tbody className=''>
              {
                feesList.length > 0 ?(
                    <>
                    {
                feesList.filter((v)=>v.feesTypeAction=="Registration")?.map((val,ind)=>{
                    return(
                        <>
                        <tr key={ind}>
                <td className='w_20'>{val.feesTypeAction}</td>
                <td className='sm_w'>{val.feesCourse}</td>
                <td className='sm_W'>  ₹ {Number(val.feesAmount).toFixed(2)}</td>
              
                <td className='sm_W'> 
                {/* <i className="fa fa-trash text-danger cur" onClick={()=>deleteFeestruct(val._id)}></i>  */}
                
                &nbsp; &nbsp;
                <i className="fa fa-edit text-primary cur" data-toggle="modal"
                            data-target='#myModal'  onClick={()=>{
                              setRegEditId(val._id);
                              setFeesTypeAction(val.feesTypeAction)
                              setFeesCourse(val.feesCourse)
                              setFeesAmount(val.feesAmount)
                              setFeesTypeText(val.feesTypeText)
                              setIsRegEdit(true)
                            }} ></i>
                </td>

              </tr>
                        </>
                    )
                })
              }
                    
                    </>
                ):(
                    <>
                    <tr>
                        <td colSpan={5}>

<div className="text-center">
<p className="m-0">No Data Found</p>
</div>

                        </td>
                    </tr>
                    </>
                )
              }
             
            </tbody>
          </table>
        </div>

                </div>
                  </div>
              </div>
           
              <div className="row mt-5">
                <div className="col-12">
                <div className="dd_flex">
                        <h3 className='text-custom'>Admission Fees</h3>
                        <button className='sm_btn' data-toggle="modal"
                            data-target='#myModalreg' onClick={()=>setFeesTypeAction("Admission")}>Admission Fees</button>

                        <div class="modal fade" id='myModalreg'>
        <div class="modal-dialog modal modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">{isregEdit ? "Edit": "Add"} Fees</h4>
              <button type="button" class="close" data-dismiss="modal" onClick={()=>{
                setFeesAmount("")
                setFeesCourse("")
             setIsRegEdit(false)
             setRegEditId("")
                setFeesTypeText("")
                setFeesTypeAction("")
              }}>
                &times;
              </button>
            </div>

            <div class="modal-body">
                <form onSubmit={submitFees}>

                <div className="form-group ">
                       <label for="address">Fees Course:</label>
                       <select
                          class="form-control form_h"
                          value={feesCourse}
                          onChange={(e) => setFeesCourse(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose--
                          </option>
                          <option value="BED">BED</option>
                          <option value="DELED">DELED</option>
                          <option value="DPHARM">DPHARM</option>

                        
                        </select>
                     </div>
                     <div className="form-group ">
                       <label for="address">Fees Amount:</label>
                       <input
                         type="number"
                         placeholder="Enter Fees Amount "
                         className="form-control form_h"
                         name="adfeesAmount"
                         value={feesAmount}
                         onChange={(e) => setFeesAmount(e.target.value)}
                         required
                       />
                     </div>

                     <div className="form-group">
                     <label>Enter Other Details:</label>
                     <CKeditorCom
                       text={feesTypeText}
                       setText={setFeesTypeText}
                     />
                   </div>
                     
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                       {isregEdit ? "Save Data": "Submit Data"}
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}

                </form>
             
            
            </div>
          </div>
        </div>
      </div>
                    </div>
                  
                </div>
                <div className="col-12">
                  <div className=" mt-5">
                 
                  <div class="table-responsive  scroll_bar">
          <table class="table table-borderless dash scroll_bar_content">
            <thead>
              <tr>
                <th className="w_20">Fees Type</th>
                <th className="sm_w">Fees Course</th>
                <th className="sm_w">Fees Amount</th>
                <th className="w_60">Fees Description</th>
                <th className='sm_w'>Operation</th>

               
                

                
              </tr>
            </thead>
            <tbody className=''>
              {
                feesList.length > 0 ?(
                    <>
                    {
                feesList.filter((v)=>v.feesTypeAction=="Admission")?.map((val,ind)=>{
                    return(
                        <>
                        <tr key={ind}>
                <td className='w_20'>{val.feesTypeAction}</td>
                <td className='sm_w'>{val.feesCourse}</td>
                <td className='sm_W'>  ₹ {Number(val.feesAmount).toFixed(2)}</td>
                <td className='w_60'>{val?.feesTypeText && parse(val.feesTypeText)} {
                      
                      }</td>
                <td className='sm_W'> 
                {/* <i className="fa fa-trash text-danger cur" onClick={()=>deleteFeestruct(val._id)}></i> */}
                
                &nbsp; &nbsp;
                <i className="fa fa-edit text-primary cur" data-toggle="modal"
                            data-target='#myModalreg'  onClick={()=>{
                              setRegEditId(val._id);
                              setFeesTypeAction(val.feesTypeAction)
                              setFeesCourse(val.feesCourse)
                              setFeesAmount(val.feesAmount)
                              setFeesTypeText(val.feesTypeText)
                              setIsRegEdit(true)
                            }} ></i>
                 </td>

              </tr>
                        </>
                    )
                })
              }
                    
                    </>
                ):(
                    <>
                    <tr>
                        <td colSpan={5}>

<div className="text-center">
<p className="m-0">No Data Found</p>
</div>

                        </td>
                    </tr>
                    </>
                )
              }
             
            </tbody>
          </table>
        </div>

                </div>
                  </div>
              </div>
            </div>
         
           
          </div>
        </div>
      </div>
      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    </>
  )
}

export default FeesOption