
// export const apilink = 'http://localhost:5000';
// export const path = 'http://localhost:4552';

export const apilink = 'https://api.edushark.in';
export const path = 'https://admin.edushark.in'

export const headerNav = [
  {
    display: 'Dashboard',
    path: '/dashboard',
  },
  {
    display: 'Colleges',
    path: '/college',
  },
  {
    display: 'Courses',
    path: '/course',
  },
  {
    display: 'Add Courses',
    path: '/add-course',
  },  
  {
      display: 'Students',
      path: '/student',
    },
    {
      display: 'Purchase Classes',
      path: 'https://class.edushark.in',
    },
    {
      display: "Purchase Books",
      path:'/purchase-book'
    },
    {
      display: 'Orders',
      path: '/orders',
    },
    {
      display: 'Payments',
      path: '/payment',
    },
    {
      display: 'Contact',
      path: '/contacts',
    },
    {
      display: 'Gallery',
      path: '/gallery',
    },
    {
      display:"Users",
      path: '/users'
    },
    {
      display:"Fees Options",
      path:"/fees-options",
    },
    
];
export const subjects = [
  "Bengali",
  "English",
  "Sanskrit",
  "Arabic",
  "Mathematics",
  "Life Science",
  "Physical Science",
  "History",
  "Geography",
  "Education",
  "Political Science",
  "Computer Science",
  "Commerce",
  "Economics",
  "Music",
  "Sociology"
];

export const banarlist = [
  {
    title: 'Best Sea Food for you',
    image: 'b1.jpg',
    link: '/foodtype/seafood',
  },
  {
    title: 'Best Cake for you',
    image: 'b2.jpg',
    link: '/foodtype/cake',
  },
  {
    title: 'Best Biryani for you',
    image: 'b3.jpg',
    link: '/foodtype/biryani',
  },
  {
    title: 'Best Burger for you',
    image: 'b4.jpg',
    link: '/foodtype/burger',
  },
  {
    title: 'Best Rolls for you',
    image: 'b5.jpg',
    link: '/foodtype/roll',
  },
  {
    title: 'Best Pizza for you',
    image: 'b6.jpg',
    link: '/foodtype/pizza',
  },
];

export const categoryList = [
  {
    title: 'Biryani',
    image: 'food1.jpg',
    link: '/foodtype/biryani',
  },
  {
    title: 'Pizza',
    image: 'food2.jpg',
    link: '/foodtype/pizza',
  },
  {
    title: 'Burger',
    image: 'food3.jpg',
    link: '/foodtype/burger',
  },
  {
    title: 'Cake',
    image: 'food4.jpg',
    link: '/foodtype/cake',
  },
  {
    title: 'Chicken',
    image: 'food5.jpg',
    link: '/foodtype/chicken',
  },
  {
    title: 'SeaFood',
    image: 'food6.png',
    link: '/foodtype/seafood',
  },
];


export const  getFormattedDate=(date)=> {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return month + '/' + day + '/' + year;
}
