



import React, { useEffect, useState } from 'react'

import './Payment.css'
import SideBar from '../../component/sidebar/SideBar'
import ReactPaginate from 'react-paginate';
import { NavLink , useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { apilink } from '../../data/fdata';
import Cookies from 'js-cookie';
import axios from 'axios';
import { CircularProgress } from "@mui/material";
import { useAlert } from "react-alert";

import { saveAs } from 'file-saver';
const Payment = () => {



  const token = Cookies.get('_edushark_admin_access_token');
  const his = useHistory();
  const alert=useAlert()

  const [pageLoading, setPageLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [myData_list, setMyData_list] = useState([]);


  const [updateStatus, setUpdateStatus] = useState("");
  const [loading,setLoading] =useState(false)
  const [popupcont, setPopupcont] = useState(false);
  const [activeid, setActiveid] = useState("");


  const [pageNo, setPageNo] = useState(0);

  const [filter, setFilter] = useState("all");

  const perpage = 15;
  const pagevisit = pageNo * perpage;

  const dataall = myData.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(myData.length / perpage);

  
  const pageChange = ({ selected }) => {
    setPageNo(selected);
  };

  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            if(res.data.userInfo?.isAdmin)
            {
              
            }else{
              window.location.href = '/login';
            }

          }
          setTimeout(() => {
            setPageLoading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);



  useEffect(()=>{

    if(filter=="all"){
      
      setMyData(myData_list);
    } else{
      setMyData(myData_list.filter(v=>v.paymentfee_status == filter));
    }

  },[filter])

  const  getAllPaymentFees=async()=>{
    const res=await axios.get(`${apilink}/api/paymentfees/getAllPaymentFees`)
    // console.log(res.data)
    if(res.data.success)
    {
      setMyData(res.data.result)
    setMyData_list(res.data.result)

    }else{
      alert.error(res.data.msg)
    }
  }

  useEffect(()=>{
    getAllPaymentFees()
  },[])



  const onUpdateStatuscontent=async(e)=>{
    e.preventDefault();
    setLoading(true)

    const data={
      activeid,
      updateStatus
    }

    const res=await axios.post(`${apilink}/api/paymentfees/updatePaymentFee`,data,{
      headers: {
        Authorization: token,
      },
    })

    if(res.data.success)
    {
      getAllPaymentFees()


      setTimeout(() => {
        setActiveid("")
        setUpdateStatus("")

        setLoading(false)
        alert.success(res.data.msg)
        setPopupcont(false)
      }, 1500);

    }else{

      setTimeout(() => {
        setActiveid("")
        setUpdateStatus("")
        setLoading(false)
        alert.error(res.data.msg)
        setPopupcont(false)
      }, 1500);

    }

    
  }

  const generatepdf=async(pid,table)=>{
    setPageLoading(true)
  
    const res=await axios.post(`${apilink}/api/pdf/generate-pdf`,{
      pid,
      table
    })
    // console.log(res.data)
    if(res.data.success)
    {
     alert.success(res.data.msg)
     const ress=await axios.get(`${apilink}/api/pdf/fetch-pdf/${pid}`,{ responseType: 'blob' })
         if(ress)
         {
             const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });
  
               saveAs(pdfBlob, `invoice_pdf_${pid}.pdf`);
               const re=await axios.get(`${apilink}/api/pdf/delete-pdf/${pid}`)
               if(!re.data.success)
               {
                 his.push("something-wrong")
               }
         }
  
    }else{
     alert.error(res.data.msg)
    }
    setPageLoading(false)
  }

  return (
    <>

{
    popupcont &&  <div className="model_box">
    <div className="inner_model">
      <div className="cross" onClick={()=>{
        setPopupcont(false)
        setActiveid("")
        setUpdateStatus("")

      }}>&times;</div>
      <div>
       <div className="text-center">
        <h5>Update Status</h5>
       
       </div>
       <form className="my-4" onSubmit={onUpdateStatuscontent}>
       <div className="form-group ">
                   
                       <select
                          class="form-control form_h"
                          value={updateStatus}
                          onChange={(e) => {
                            setUpdateStatus(e.target.value)
                            
                          }}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Status--
                          </option>
                          <option value="PENDING">Pending</option>
                          <option value="APPROVED">Approved</option>
                          <option value="REJECTED">Rejected</option>

                       
                        </select>
                       
                     </div>
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                      {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                    </div>
        </form>
      </div>
    </div>
  </div>
   }

    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
         <div className="row">
         <div className="col-12">
                <div className="card p-2">
                  <div className="dd_flex">
                  <h5 className='text-custom'>Fees Payments</h5>
                  <div className='dd_flex'>
            <div class="form-group">
              <select class="form-control filterform" value={filter} onChange={(e)=>setFilter(e.target.value) }>
               
                <option value="all">All</option>
                <option value="APPROVED">Approved</option>
                <option value="REJECTED">Rejected</option>
                <option value="PENDING">Pending</option>
              </select>
            </div>
           
          </div>
                  </div>

                  {
                  dataall.length > 0 ?(
                    <>
                     <div class="table-responsive scroll_bar">
          <table class="table table-borderless dash  scroll_bar_content">
            <thead>
              <tr>
                {/* <th className="w_20">Id</th> */}
                <th className="w_20">UserName</th>
                <th className="w_20">Phone</th>
                <th className="w_20">Type</th>
                <th className="w_60">FeesType</th>
                <th className="w_60">CollegeName</th>
                <th className="w_20">CourseName</th>

                <th className="w_20">PaymentId</th>
               <th className='w_20'>Amount</th>
               <th className="w_20">PayStatus</th>
                <th className="sm_w">Status</th>
                <th className='sm_w'>Operation</th>
              </tr>
            </thead>
            <tbody className=''>
              {
                dataall?.map((val,ind)=>{
                  return(
                    <>
                    <tr>
                {/* <td><small>{val._id}</small></td> */}
                <td>{val?.userID?.name}</td>
                <td><a href={`tel:${val?.userID?.phone}`}>{val?.userID?.phone}</a></td>
                <td>
                  {val.pay_type}
                </td>
                <td>
                  {val.feestype}
                </td>
                <td>
                  {val.collegeID?.col_name}
                </td>
                <td>
                  {val.applicationID?.course_name}
                </td>
                <td>
                 {val.payment_id}
                </td>
                <td>
                ₹ {Number(val.feesamount).toFixed(2)}
                </td>
                <td>{val.payment_status  ? <>
                <p className='m-0 text-success'>PAID &nbsp; <span onClick={()=>generatepdf(val._id,val.pay_type)} className='badge badge-light py-1 px-2 cur'><i className='fa fa-download mr-1'></i> Receipt</span></p>
                
                </>: 
                <>
                <p className='m-0 text-danger'>UNPAID/FAILED</p>
                </>
                } </td>
                <td> 

                {val.paymentfee_status=="PENDING" &&  <span className='badge badge-warning'>Pending</span>}
                  {val.paymentfee_status=="APPROVED" &&  <span className='badge badge-success'>Approved</span>}
                  {val.paymentfee_status=="REJECTED" &&  <span className='badge badge-danger'>Rejected</span>}


                </td>
                <td>
                  <button className='sm_btn' onClick={()=>{
                    setPopupcont(true)
                    setActiveid(val._id)
                  }}>Update</button>

                </td>
              </tr>
                    </>
                  )
                })
              }
             
            </tbody>
          </table>
        </div>
                    
                    </>
                  ):(
                    <>
                    <div className="py-4 text-center">
                      <h5>No Item Found</h5>
                    </div>
                    </>
                  )
                 }

        <div className="row pt-4">
         <div className="col-12">
         {
          dataall.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxno}
          onPageChange={pageChange}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>

                  </div>
                </div>
         </div>
           
          </div>
        </div>
      </div>
      {
        pageLoading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    </>
  )
}

export default Payment