import React, { useEffect, useState } from 'react'
import './Contact.css'
import SideBar from '../../component/sidebar/SideBar';
import ReactPaginate from 'react-paginate';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useAlert } from 'react-alert';

const Contact = () => {
    const [pageloading, setPageLoading] = useState(false);
    const [myData, setMyData] = useState([]);
    const [saveData, setSaveData] = useState([]);

    const token = Cookies.get('_edushark_admin_access_token');
  const his = useHistory();
 const alert= useAlert()

    const [pageNo, setPageNo] = useState(0);
    const [filter, setFilter] = useState("all");
  
    const perpage = 15;
    const pagevisit = pageNo * perpage;
  
    const dataall = myData.slice(pagevisit, pagevisit + perpage);
    const boxno = Math.ceil(myData.length / perpage);
  
    const pageChange = ({ selected }) => {
      setPageNo(selected);
    };

    useEffect(async() => {
        if(token) {
          setPageLoading(true)
            const res=await axios.get(`${apilink}/api/admin/authVerify`,{
                headers: {
                  Authorization: token,
                },
              })
              // console.log(res.data)
              if(!res.data.success)
              {
                Cookies.remove('_edushark_admin_access_token');
               localStorage.removeItem('_edushark_admin_access_login');
              console.clear();
              window.location.href = '/login';
              }else{
               
                if(!res.data.userInfo?.isAdmin)
                {
                  window.location.href = '/login';
                }
      
              }
              setTimeout(() => {
                setPageLoading(false)
              }, 1500);
             
        }else{
          his.push("/login")
        }
      }, []);

    useEffect(()=>{

        if(filter=="all"){
          
          setMyData(saveData);
        } else{
          setMyData(saveData.filter(v=>v.isChecked==filter));
        }
    
      },[filter])


      const getContacts=async()=>{
        const res=await axios.get(`${apilink}/api/contact/getContacts`)
        setMyData(res.data.result)
        setSaveData(res.data.result)
      }


      const updateStatus=async(id)=>{

        const res=await axios.get(`${apilink}/api/contact/updateContact/${id}`,{
            headers: {
                Authorization: token,
              },
        })
        // console.log(res.data)
        if(res.data.success){
            alert.success(res.data.msg)
            getContacts()

        }else{
            alert.error(res.data.msg)
        }

      }

useEffect(()=>{
    getContacts()

},[])

  return (
    <>

<div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">

            <div className="container-fluid">
                <div className="row">
                    
                    <div className="col-12">
                    <h3 className='text-custom mb-2'>All Messages</h3>
                        <div className="card p-3">

                        <div className="dd_flex">
                  <p></p>
                  <div className='dd_flex'>
            <div class="form-group">
              <select class="form-control filterform" value={filter} onChange={(e)=>setFilter(e.target.value) }>
               
                <option value="all">All</option>
                <option value="Seen">Seen</option>
                <option value="Pending">Pending</option>
              </select>
            </div>
            </div>
           
          </div>

          {
                dataall.length > 0 ?(
                    <>
                    <div class="table-responsive  scroll_bar">
          <table class="table table-borderless dash scroll_bar_content">
            <thead>
              <tr>
                <th className="w_20">Name</th>
                <th className="w_20">Phone</th>
                <th className="w_60">Comments</th>               
                <th className="w_60">Date</th>
                <th className='w_60'>Operation</th>
                
              </tr>
            </thead>
            <tbody className=''>

                {
                     dataall?.map((val,ind)=>{
                        return(
                          <>
                          <tr key={ind}>
                              <td>{val.fname} {val.lname}</td>
                              <td><a href={`tel:${val.phone}`}>{val.phone}</a></td>
                              <td>{val.comment}</td>
                              <td>{new Date(val.createdAt).toDateString()}</td>
                              <td>{val.isChecked == "Pending" && <> <span onClick={()=>updateStatus(val._id)} className='cur badge badge-warning'>Pending</span> </> }
                              {val.isChecked == "Seen" && <> <span className='badge badge-success'>Seen</span> </> }
                              </td>
                      
                          
                     
                    </tr>
                          </>
                        )
                      })
                }
             
             
            </tbody>
          </table>
        </div>
                    </>
                ):(
                    <>
                    
                    <div className="  text-center">
                        <p >No item Found</p>
                    </div>
                    
                    
                    </>
                )
              }

                        



                        <div className="row pt-4">
         <div className="col-12">
         {
          dataall.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxno}
          onPageChange={pageChange}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>


                        </div>
                    </div>
                </div>
            </div>
         
           
          </div>
        </div>
      </div>
      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    
    
    
    </>
  )
}

export default Contact