import React from 'react'
import axios from 'axios'
import { useState } from 'react'
import { apilink } from '../../data/fdata'
import { useEffect } from 'react'
import { CircularProgress } from "@mui/material";
import Cookies from 'js-cookie'
import { saveAs } from 'file-saver';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'


const OrderBookCompo = ({setFilterpayment , orderbook , alert , apitoken , getAllBookOrders , setPageLoading}) => {
    const token = Cookies.get('_edushark_admin_access_token');
    const his = useHistory();
    const [updateStatus, setUpdateStatus] = useState("");
  const [loading,setLoading] =useState(false)
  const [popupcont, setPopupcont] = useState(false);
  const [activeid, setActiveid] = useState("");


const [orderStatus,setOrderStatus]=useState({})
    

    const getShipOrderDetails=async()=>{
        const res=await axios.post(`${apilink}/api/shiprocket/getOrderByship_order_id`,{
            token:apitoken,
            'ship_order_id': orderbook['ship_order_id']
                })
                // console.log(res.data.result)
                if(res.data.success)
                {
                    setOrderStatus(res.data.result.data)
                }else{
                    alert.error(res.data.msg)
                }
    }

    useEffect(()=>{
        if(orderbook?.ship_order_id){
            getShipOrderDetails()
        }

    },[orderbook?.ship_order_id])

    const onUpdateStatuscontent=async(e)=>{
        e.preventDefault();
        setLoading(true)
    
        const data={
          activeid,
          updateStatus
        }
    
        const res=await axios.post(`${apilink}/api/orderbook/updateOrderbook`,data,{
          headers: {
            Authorization: token,
          },
        })
    
        if(res.data.success)
        {
            getAllBookOrders()
    
    
          setTimeout(() => {
            setActiveid("")
            setUpdateStatus("")
    
            setLoading(false)
            alert.success(res.data.msg)
            setPopupcont(false)
          }, 2000);
    
        }else{
    
          setTimeout(() => {
            setActiveid("")
            setUpdateStatus("")
            setLoading(false)
            alert.error(res.data.msg)
            setPopupcont(false)
          }, 2000);
    
        }
    
        
      }

      const generateOrderpdf=async(vid)=>{
        setPageLoading(true)
  
        const res=await axios.post(`${apilink}/api/pdf/order-pdf`,{
          oid:vid,
          
        })
        // console.log(res.data)
        if(res.data.success)
        {
         alert.success(res.data.msg)
         const ress=await axios.get(`${apilink}/api/pdf/fetch-pdf/${vid}`,{ responseType: 'blob' })
             if(ress)
             {
                 const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });
  
                   saveAs(pdfBlob, `order_pdf_${vid}.pdf`);
                   const re=await axios.get(`${apilink}/api/pdf/delete-pdf/${vid}`)
                   if(!re.data.success)
                   {
                     his.push("something-wrong")
                   }
             }
  
        }else{
         alert.error(res.data.msg)
        }
        setPageLoading(false)
      }

      const deleteBookOrder=async(id)=>{
        
        setPageLoading(true)

          const res=await axios.get(`${apilink}/api/orderbook/deleteOrderBookById/${id}`,{
              headers: {
          
                  Authorization: token,
                },
          })
          // console.log(res.data)
          if(res.data.success)
          {
              alert.success(res.data.msg)
              setFilterpayment("all")
              getAllBookOrders()
              
          }else{
              alert.error(res.data.msg)
          }
          setTimeout(() => {
        setPageLoading(false)
            
          }, 2000);
        
      }

  return (
    <>
    {
    popupcont &&  <div className="model_box">
    <div className="inner_model">
      <div className="cross" onClick={()=>{
        setPopupcont(false)
        setActiveid("")
        setUpdateStatus("")

      }}>&times;</div>
      <div>
       <div className="text-center">
        <h5>Update Status</h5>
       
       </div>
       <form className="my-4" onSubmit={onUpdateStatuscontent}>
       <div className="form-group ">
                   
                       <select
                          class="form-control form_h"
                          value={updateStatus}
                          onChange={(e) => {
                            setUpdateStatus(e.target.value)
                            
                          }}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Status--
                          </option>
                          <option value="PENDING">Pending</option>
                          <option value="APPROVED">Approved</option>
                          <option value="REJECTED">Rejected</option>

                       
                        </select>
                       
                     </div>
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                      {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                    </div>
        </form>
      </div>
    </div>
  </div>
   }

    <tr>
      <td>{new Date(orderbook.createdAt).toLocaleDateString('en-GB')}</td>
                <td><small className='m-0 cur text-custom' data-toggle="modal"
                            data-target={`#myorderModal${orderbook._id}`}>{orderbook._id}</small></td>
                <td>{orderbook?.stu_id?.name}</td>
                <td><a href={`tel:${orderbook?.stu_id?.phone}`}>{orderbook?.stu_id?.phone}</a></td>
                <td><p className='m-0 cur text-custom' data-toggle="modal"
                            data-target={`#myModal${orderbook._id}`} >{orderbook?.book_id?.bookname}</p></td>
               <td>
                 {orderbook.payment_id}
                </td>
                <td>
                
                ₹ {Number(orderbook.payment_with_shipping).toFixed(2)}
                
                </td>
                <td>
              

                  {
                    orderbook.payment_status  ? (
                      <>
                        <p className='m-0 text-success'>PAID &nbsp; <span onClick={()=>generateOrderpdf(orderbook._id )} className='badge badge-light py-1 px-2 cur'><i className='fa fa-download mr-1'></i> Receipt</span></p>
                      </>
                    ):(
                      <>
                      <p className='m-0 text-danger'>UNPAID/FAILED</p>
                      </>
                    )
                  }
                </td>
                <td> {
                    !orderStatus.status ? <> <small>loading...</small> </> : (
                        <>
                        {
                    orderStatus.status == "CANCELED" ? <span className='badge badge-danger'>{orderStatus.status}</span> : <span className='badge badge-success'>{orderStatus.status}</span>
                    
                    } 
                    {
                        orderStatus.status == "NEW" ? "": <p className='m-0 fn_12'> <small>On <b>{new Date(orderStatus.updated_at).toDateString()}</b></small> </p>
                    }
                        </>
                    )
                    }
                     </td>

                <td>  {orderbook?.order_status=="PENDING" &&  <span className='badge badge-warning'>Pending</span>}
                  {orderbook?.order_status=="APPROVED" &&  <span className='badge badge-success'>Approved</span>}
                  {orderbook?.order_status=="REJECTED" &&  <span className='badge badge-danger'>Rejected</span>}
</td>

              <td><button className='sm_btn' onClick={()=>{
                    setPopupcont(true)
                    setActiveid(orderbook._id)
                  }}>Update</button> 
                  {
                    !orderbook.payment_status &&  <button className='sm_btn ml-2 bg-danger ' onClick={()=>{
                      deleteBookOrder(orderbook._id)
                    }}> <i className='fa fa-trash'></i> </button>
                  } 
                  
                  
                  </td>
              </tr>


              <div class="modal fade" id={`myModal${orderbook?._id}`}>
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Book Details</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              
            <h5><span className='text-custom'>Name: &nbsp;</span>{orderbook?.book_id?.bookname} </h5>
              <h5><span className='text-custom'>Author: &nbsp;</span>{orderbook?.book_id?.bookauthor} </h5>
              <h5><span className='text-custom'>Category: &nbsp;</span>{orderbook?.book_id?.bookcategory} </h5>
              <h5><span className='text-custom'>Semester or Year: &nbsp;</span>{orderbook?.book_id?.booksem} </h5>
              <h5><span className='text-custom'>Publisher: &nbsp;</span>{orderbook?.book_id?.bookpublisher} </h5>
              <h5><span className='text-custom'>Price: &nbsp;</span>{orderbook?.book_id?.bookprice} </h5>
              <h5><span className='text-custom'>Number of Books: &nbsp;</span>{orderbook?.book_id?.booknumber} </h5>
              <h5><span className='text-custom'>Number of Pages: &nbsp;</span>{orderbook?.book_id?.bookpages} </h5>



              
            
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id={`myorderModal${orderbook?._id}`}>
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Order Details</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              
            <h5><span className='text-custom'>Ordered Book: &nbsp;</span>{orderbook?.book_id?.bookname} </h5>
            <h5><span className='text-custom'>Book Category: &nbsp;</span>{orderbook?.book_id?.bookcategory} </h5>

            <h5><span className='text-custom'>Book Price: &nbsp;</span>₹ {Number(orderbook?.book_price).toFixed(2)} </h5>
            
            <h5><span className='text-custom'>Qty: &nbsp;</span>{orderbook?.book_qty} </h5>
            <h5><span className='text-custom'>Total Book Price: &nbsp;</span>₹ {Number(orderbook?.total_book_price).toFixed(2)} </h5>

            <h5><span className='text-custom'>Delivery Price: &nbsp;</span>₹{ Number(orderbook?.delivery_price).toFixed(2)} </h5>
            <h5><span className='text-custom'>Total Amount: &nbsp;</span>₹ {Number(orderbook?.payment_with_shipping).toFixed(2)} </h5>

<hr />
<h5>Address Details</h5>

<h5><span className='text-custom'>House_address: &nbsp;</span>{orderbook?.shiprocket?.billing_address} </h5>
<h5><span className='text-custom'>Street_address: &nbsp;</span>{orderbook?.shiprocket?.billing_address_2} </h5>
<h5><span className='text-custom'>City: &nbsp;</span>{orderbook?.shiprocket?.billing_city} </h5>
<h5><span className='text-custom'>Pincode: &nbsp;</span>{orderbook?.shiprocket?.billing_pincode} </h5>
<h5><span className='text-custom'>State: &nbsp;</span>{orderbook?.shiprocket?.billing_state} </h5> 
<h5><span className='text-custom'>Customer Name: &nbsp;</span>{orderbook?.shiprocket?.billing_customer_name} </h5>           
<h5><span className='text-custom'>Email: &nbsp;</span>{orderbook?.shiprocket?.billing_email} </h5>           
<h5><span className='text-custom'>Phone: &nbsp;</span>{orderbook?.shiprocket?.billing_phone} </h5>           




              
            
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderBookCompo