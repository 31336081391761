import React from 'react'
import './PurchesCourse.css'
import { useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';
import { CircularProgress } from "@mui/material";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import axios from 'axios'
import SideBar from '../../component/sidebar/SideBar';
import { apilink } from '../../data/fdata';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const EditPurchesBook = () => {
    const token = Cookies.get('_edushark_admin_access_token');
    const his = useHistory();
     const alert= useAlert()
     const [pageloading, setPageLoading] = useState(false);
 const [somethingWrong, setSomethingWrong] = useState(false);


    const [bookcategory, setBookCategory] = useState('');  
    const [booksem, setBookSem] = useState('');
    const [booklang, setBookLang] = useState('');  
    const [bookimage, setBookImage] = useState([]);
    const [bookimage_urls, setBookImage_urls] = useState([]);

    const [bookname, setBookName] = useState('');
    const [bookauthor, setBookAuthor] = useState('');
    const [bookpublisher, setBookPublisher] = useState('');
    const [bookdimension_length, setBookDimension_length] = useState("");
    const [bookdimension_width, setBookDimension_width] = useState("");
    const [bookdimension_height, setBookDimension_height] = useState("");
    const [bookweight, setBookWeight] = useState("");
    const [bookpages, setBookPages] = useState("");  
    const [bookprice, setBookPrice] = useState('');
    const [booknumber, setBookNumber] = useState('');
    const [bookdiscount,setBookdiscount]=useState('')

  

 const [loading, setLoading] = useState(false);

 const {bookid}=useParams()
  
  
 useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            if(!res.data.userInfo?.isAdmin)
            {
              window.location.href = '/login';
            }
  
          }
          setTimeout(() => {
            setPageLoading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);

 const onSubmitForm=async(e)=>{
    e.preventDefault();
    setLoading(true)
// console.log(bookimage.length)
    if(bookimage.length){
        let formData = new FormData();
      
        Array.from(bookimage).forEach(image => {
          formData.append("file", image);
      });
    
      const res = await axios.post(`${apilink}/api/file/upload`, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: token,
        },
      });
      if(res.data.success)
      {
        const bookData = {
            bookid,
            bookcategory,
            booksem,
            booklang,
            bookimage:res.data.imageUrls,
            bookname,
            bookauthor,
            bookpublisher,
            bookdimension_length,
            bookdimension_width,
            bookdimension_height,
            bookweight,
            bookpages,
            bookprice,
            booknumber,
            bookdiscount
        };
    
       
      
        const ress=await axios.post(`${apilink}/api/purchesbook/updatebook`,bookData,{
            headers: {
              
                Authorization: token,
              },
        });
        // console.log(ress.data)
        if(ress.data.success)
        {
            alert.success(ress.data.msg)
            setTimeout(() => {
              his.push("/purchase-book")
            }, 1500);
        }else{
            alert.error(ress.data.msg)
            setSomethingWrong(true)
        }
    
      }else{
        alert.error(res.data.msg)
    setSomethingWrong(true)
      }
    
    }else{
        const bookData = {
            bookid,
            bookcategory,
            booksem,
            booklang,
            bookimage:bookimage_urls,
            bookname,
            bookauthor,
            bookpublisher,
            bookdimension_length,
            bookdimension_width,
            bookdimension_height,
            bookweight,
            bookpages,
            bookprice,
            booknumber,
            bookdiscount
        };
    
        const ress=await axios.post(`${apilink}/api/purchesbook/updatebook`,bookData,{
            headers: {
              
                Authorization: token,
              },
        });
        // console.log(ress.data)
        if(ress.data.success)
        {
            alert.success(ress.data.msg)
            setTimeout(() => {
              his.push("/purchase-book")
            }, 1500);
        }else{
            alert.error(ress.data.msg)
            setSomethingWrong(true)
        }
      
       

    }

   
   
  
  
    setLoading(false)

 }

 const handelimage = (e) => {
    const { files } = e.target;
    let f = false;
    let filetype = ['image/jpeg', 'image/jpg', 'image/png'];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        // console.log(files[i].type);
        if (files[i].size > 2000000) {
          f = true;
          // console.log('ok');
        }

        if (!filetype.includes(files[i].type)) {
          f = true;
          // console.log('hi');
        }
      }
      if (f) {
        // console.log(f);
        alert.error(
          'Upload images with proper file size (2MB) & extension ( jpg, png , jpeg)'
        );
        f = false;
      }else{
        // console.log();
        setBookImage(e.target.files)
      }
    }
  };


  const getcontentbyid=async()=>{
    const res=await axios.get(`${apilink}/api/purchesbook/getcontentbyid/${bookid}`)
    // console.log(res.data)
    if(res.data.success)
    {
        setBookCategory(res.data.result.bookcategory)
        setBookSem(res.data.result.booksem)
        setBookLang(res.data.result.booklang)
    
        setBookImage_urls(res.data.result.bookimage)

        setBookName(res.data.result.bookname)
        setBookAuthor(res.data.result.bookauthor)
        setBookPublisher(res.data.result.bookpublisher)
        setBookDimension_length(res.data.result.bookdimension_length)
        setBookDimension_width(res.data.result.bookdimension_width)
        setBookDimension_height(res.data.result.bookdimension_height)
        setBookWeight(res.data.result.bookweight)
        setBookPages(res.data.result.bookpages)
        setBookPrice(res.data.result.bookprice)
        setBookNumber(res.data.result.booknumber)
        setBookdiscount(res.data.result.bookdiscount)

    }else{
        alert.error(res.data.msg)
        setSomethingWrong(true)
    }
  }
  
  useEffect(()=>{
    if(bookid)
    {
      getcontentbyid()
    }
  },[bookid])

  
  return (
    <>
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            {
              somethingWrong ?(
                <>
                <div className="card rounded py-3  px-2 ">
                        <h5 className='text-danger text-center'>Something Went Wrong...</h5>
                    </div>
                </>
              ):(
                <>

<div className="container-fluid">
          <h3 className="text_custom">Edit Books</h3>
            <div className="row">
              <div className="col-12">
                <form className='mt-2' onSubmit={onSubmitForm}>

                <div class="form-row">
                     <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Book Name:</label>
                       <input
                         type="text"
                         placeholder="Enter Book Name"
                         className="form-control form_h"
                         name="bookname"
                         value={bookname}
                         onChange={(e) => setBookName(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-6 col-md-6">
                       <label htmlFor=""> Author Name:</label>
                       <input
                         type="text"
                         placeholder="Enter Author Name"
                         className="form-control form_h"
                         name="bookauthor"
                         value={bookauthor}
                         onChange={(e) => setBookAuthor(e.target.value)}
                         required
                       />
                     </div>
                   </div>

                   <div class="form-row">
                   <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Category:</label>
                       <select
                          class="form-control form_h"
                          value={bookcategory}
                          onChange={(e) => setBookCategory(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Category--
                          </option>
                          <option value="BED">B.ed</option>
                          <option value="DELED">D.el.ed</option>
                          <option value="DPHARMA">D.pharma</option>
                          <option value="Others">Others</option>

                         
                        </select>
                       
                     </div>
                     <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Semester of year:</label>
                       <select
                          class="form-control form_h"
                          value={booksem}
                          onChange={(e) => setBookSem(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Semester--
                          </option>
                          <option value="1st Semester">1st Semester</option>
                          <option value="2nd Semester">2nd Semester</option>
                          <option value="3rd Semester">3rd Semester</option>
                          <option value="4th Semester">4th Semester</option>
                          <option value="Others">Others</option>



                         
                         
                        </select>
                       
                     </div>
                   </div>
                   <div class="form-row">
                   <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Language:</label>
                       <select
                          class="form-control form_h"
                          value={booklang}
                          onChange={(e) => setBookLang(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Language--
                          </option>
                          <option value="Bengali">Bengali</option>
                          <option value="English">English</option>
                          <option value="Others">Others</option>

                         
                        </select>
                       
                     </div>
                     <div className="form-group col-lg-6 col-md-6">
                     <label>  <span className='text-custom cur' data-toggle="modal"
                            data-target='#myModal'>View Old Images</span> or Upload New Images:</label>

<div class="modal fade" id='myModal'>
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">View Images</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              <div className="img_flex">
                {
                  bookimage_urls?.map((va,index)=>{
                    return (
                      <div className="img_flex_item" key={index}>
                  <img src={va} alt="" />
                </div>
                    )
                  })
                }
              </div>
            
            </div>
          </div>
        </div>
      </div>
                       <input
                         type="file"
                        
                         className="form-control form_h"
                         name="file"
                         multiple
                         accept=".png, .jpeg , .jpg"
                        
                         onChange={handelimage}
                         
                       />
                      
                     </div>
                   </div>

                   {/* <div class="form-row">
                  
                  <div className="form-group col-lg-4 col-md-4">
                    <label for="address">Publisher Name:</label>
                    <input
                      type="text"
                      placeholder="Enter Publisher Name"
                      className="form-control form_h"
                      name="bookpublisher"
                      value={bookpublisher}
                      onChange={(e) => setBookPublisher(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group col-lg-4 col-md-4">
                    <label htmlFor=""> Book Price:</label>
                    <input
                      type="text"
                      placeholder="Enter Book Price"
                      className="form-control form_h"
                      name="bookprice"
                      value={bookprice}
                      onChange={(e) => setBookPrice(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group col-lg-4 col-md-4">
                    <label htmlFor=""> Book Discount:</label>
                    <input
                      type="number"
                      placeholder="Enter Book Discount"
                      className="form-control form_h"
                      name="bookdiscount"
                      value={bookdiscount}
                      onChange={(e) => setBookdiscount(e.target.value)}
                      required
                    />
                  </div>
                </div> */}


<div class="form-row">
                  
                  <div className="form-group col-lg-6 col-md-6">
                    <label for="address">Publisher Name:</label>
                    <input
                      type="text"
                      placeholder="Enter Publisher Name"
                      className="form-control form_h"
                      name="bookpublisher"
                      value={bookpublisher}
                      onChange={(e) => setBookPublisher(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group col-lg-6 col-md-6">
                    <label htmlFor=""> Book Price:</label>
                    <input
                      type="number"
                      placeholder="Enter Book Price"
                      className="form-control form_h"
                      name="bookprice"
                      value={bookprice}
                      onChange={(e) => setBookPrice(e.target.value)}
                      required
                    />
                  </div>
                  
                </div>

                   <p>Dimension</p>
                   <div class="form-row">
                     <div className="form-group col-lg-4 col-md-4">
                       <label for="address">Length:</label>
                       <input
                         type="number"
                         placeholder="Enter Length"
                         className="form-control form_h"
                         name="bookdimension_length"
                         value={bookdimension_length}
                         onChange={(e) => setBookDimension_length(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-4 col-md-4">
                       <label for="address">Width:</label>
                       <input
                         type="number"
                         placeholder="Enter Width"
                         className="form-control form_h"
                         name="bookdimension_width"
                         value={bookdimension_width}
                         onChange={(e) => setBookDimension_width(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-4 col-md-4">
                       <label for="address">Height:</label>
                       <input
                         type="number"
                         placeholder="Enter Height"
                         className="form-control form_h"
                         name="bookdimension_height"
                         value={bookdimension_height}
                         onChange={(e) => setBookDimension_height(e.target.value)}
                         required
                       />
                     </div>
                   </div>
                   <div class="form-row">
                     <div className="form-group col-lg-4 col-md-4">
                       <label for="address">Weight in Kg:</label>
                       <input
                         type="number"
                         placeholder="Enter Weight in Kg"
                         className="form-control form_h"
                         name="bookweight"
                         value={bookweight}
                         onChange={(e) => setBookWeight(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-4 col-md-4">
                       <label htmlFor=""> No of Pages:</label>
                       <input
                         type="number"
                         placeholder="Enter No of Pages"
                         className="form-control form_h"
                         name="bookpages"
                         value={bookpages}
                         onChange={(e) => setBookPages(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-4 col-md-4">
                       <label htmlFor=""> No of Books:</label>
                       <input
                         type="text"
                         placeholder="Enter No of Books"
                         className="form-control form_h"
                         name="booknumber"
                         value={booknumber}
                         onChange={(e) => setBookNumber(e.target.value)}
                         required
                       />
                     </div>
                   </div>

                   
                  

                   <div className="text-right">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Update Details
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                   
                </form>
                
              </div>
            </div>

          </div>
                
                </>
              )
            }
         
         
           
          </div>
        </div>
      </div>
      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    </>
  )
}

export default EditPurchesBook