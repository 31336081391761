import React from 'react'
import SideBar from '../../component/sidebar/SideBar'
import { useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import { useEffect } from 'react';
import { useHistory , useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { CircularProgress } from '@mui/material';

const EditCollege = () => {
    const [pageloading, setPageLoading] = useState(false);
    const [errorFound, setErrorFound] = useState(false);

    const alert=useAlert()
const [loading,setLoading] =useState(false)


const [collegeName,setCollegeName] = useState("")
const [collegeImage,setCollegeImage] = useState([])
const [oldcollegeImage,setOldCollegeImage] = useState([])

const [location,setLocation] = useState("")
const [description,setDescription]= useState("")

const token = Cookies.get('_edushark_admin_access_token');
const his = useHistory();
const {colid}=useParams()

useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            if(!res.data.userInfo?.isAdmin)
            {
              window.location.href = '/login';
            }
  
          }
          setTimeout(() => {
            setPageLoading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);


  useEffect(async()=>{
    if(colid)
    {
        const res=await axios.get(`${apilink}/api/college/getCollegeById/${colid}`)
        // console.log(res.data)

        if(res.data.success)
        {
            setCollegeName(res.data.result.col_name)
            setDescription(res.data.result.col_desc)
            setLocation(res.data.result.col_location)
            setOldCollegeImage(res.data.result.col_images)

        }else{
            alert.error(res.data.msg)
      setErrorFound(true)

        }
    }

  },[colid])


  const updateCollege=async(e)=>{
    e.preventDefault()
    setLoading(true)
    let data
    if(collegeImage.length > 0)
    {
        let formData = new FormData();
      
    Array.from(collegeImage).forEach(image => {
      formData.append("file", image);
  });

  const res = await axios.post(`${apilink}/api/file/upload`, formData, {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: token,
    },
  });

  if(res.data.success) {
    data={
        collegeId:colid,
        col_name:collegeName,
  col_images:res.data.imageUrls,
  col_location:location,
  col_desc:description
    }

  }else{
alert.error(res.data.msg)
setErrorFound(true)

  }

        

    }else{

        data={
            collegeId:colid,
            col_name:collegeName,
      col_images:oldcollegeImage,
      col_location:location,
      col_desc:description
        }
    }



    // console.log(data)

    const res=await axios.post(`${apilink}/api/college/updateCollegeDetails`,data,{
        headers: {
        
            Authorization: token,
          },
    })
    // console.log(res.data)

    if(res.data.success) 
    {
        alert.success(res.data.msg)
        setTimeout(() => {
            his.push("/college")
            
        }, 1500);
    }else{
        alert.error(res.data.msg)
        setErrorFound(true)

    }


    setLoading(false)

  }


  const handelimage = (e) => {
    const { files } = e.target;
    let f = false;
    let filetype = ['image/jpeg', 'image/jpg', 'image/png'];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        // console.log(files[i].type);
        if (files[i].size > 2000000) {
          f = true;
          // console.log('ok');
        }

        if (!filetype.includes(files[i].type)) {
          f = true;
          // console.log('hi');
        }
      }
      if (f) {
        // console.log(f);
        alert.error(
          'Upload images with proper file size (2MB) & extension ( jpg, png , jpeg)'
        );
        f = false;
      }else{
        // console.log();
        setCollegeImage(e.target.files)
      }
    }
  };


 

  return (
    <>
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
         {
            errorFound ?(
                <>
                <div className="card rounded py-3  px-2 ">
                        <h5 className='text-danger text-center'>Something Went Wrong...</h5>
                    </div>
                
                </>
            ):(
                <>
                 <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-12 mb-3">
                  <div className="card p-3">
                    <h4 className='text-custom mb-4'>Update College</h4>
                    <form onSubmit={updateCollege}>

                    <div className="form-group ">
                       <label for="address">Enter College Name:</label>
                       <input
                         type="text"
                         placeholder="Ex: ABC Training Institute"
                         className="form-control form_h"
                         name="name"
                         value={collegeName}
                         onChange={(e) => setCollegeName(e.target.value)}
                         required
                       />
                     </div>

                     <div className="form-group ">
                       <label for="address"> <span className='text-custom cur' data-toggle="modal"
                            data-target='#myModal'>View Old Images</span> or Upload New Images:</label>

<div class="modal fade" id='myModal'>
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">View Images</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              <div className="img_flex">
                {
                  oldcollegeImage?.map((va,index)=>{
                    return (
                      <div className="img_flex_item" key={index}>
                  <img src={va} alt="" />
                </div>
                    )
                  })
                }
              </div>
            
            </div>
          </div>
        </div>
      </div>
                       <input
                         type="file"
                        
                         className="form-control form_h"
                         name="file"
                         multiple
                         accept=".png, .jpeg , .jpg"
                        
                         onChange={handelimage}
                        
                       />
                     </div>

                     <div className="form-group ">
                       <label for="address">Enter College Location (iframe Url):</label>
                       <input
                         type="text"
                         placeholder="Ex:  https://www.google.com/maps/embed..."
                         className="form-control form_h"
                         name="location"
                         value={location}
                         onChange={(e) => setLocation(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group ">
                       <label htmlFor="">Enter Description</label>
                       
                          <textarea
                            class="form-control"
                            rows="5"
                            placeholder="Enter College Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                          ></textarea>
                      
                       
                     </div>

                    <div className="text-right">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Update Details
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                   

                    </form>
                  </div>
                </div>
                
              </div>
             
            </div>
                </>
            )
         }
           
          </div>
        </div>
      </div>
      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    
    </>
  )
}

export default EditCollege