import React from 'react'
import './PurchesCourse.css'
import { useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';
import { CircularProgress } from "@mui/material";
import {NavLink , useHistory } from 'react-router-dom/cjs/react-router-dom.min'


import axios from 'axios'
import SideBar from '../../component/sidebar/SideBar';
import { apilink } from '../../data/fdata';
import { useEffect } from 'react';

const PurchesCourse = () => {
    const token = Cookies.get('_edushark_admin_access_token');
    const his = useHistory();
     const alert= useAlert()
     const [cor_List, setCor_List] = useState([]);
     const [cor_Id, setCor_Id] = useState("");
     const [title, setTitle] = useState('');
     const [sml_desc, setSml_desc] = useState('');
     const [ video_url, setVideo_url] = useState('');

    
     const [loading,setLoading] =useState(false)
     const [popup, setPopup] = useState(false);
 
     const [pageloading, setPageLoading] = useState(false);
  

  
     useEffect(async() => {
        if(token) {
          setPageLoading(true)
            const res=await axios.get(`${apilink}/api/admin/authVerify`,{
                headers: {
                  Authorization: token,
                },
              })
              // console.log(res.data)
              if(!res.data.success)
              {
                Cookies.remove('_edushark_admin_access_token');
               localStorage.removeItem('_edushark_admin_access_login');
              console.clear();
              window.location.href = '/login';
              }else{
               
                if(!res.data.userInfo?.isAdmin)
                {
                  window.location.href = '/login';
                }
      
              }
              setTimeout(() => {
                setPageLoading(false)
              }, 1500);
             
        }else{
          his.push("/login")
        }
      }, []);

      const getAllYoutubeCourses=async()=>{
        const res=await axios.get(`${apilink}/api/purchescourse/getyoutubecourses`)
        // console.log(res.data)
        if(res.data.success)
        {
            setCor_List(res.data.result)
        }else{
            alert.error(res.data.msg)
        }
      }


      useEffect(()=>{
        getAllYoutubeCourses()
      },[])
  
      const onDelete=async(id)=>{
        const res=await axios.get(`${apilink}/api/purchescourse/deleteyoutubecourse/${id}`,{
            headers: {
                Authorization: token,
              },
        })
        // console.log(res.data)
        if(res.data.success)
        {
            setCor_List(cor_List.filter((v)=>v._id != id))
            alert.success(res.data.msg)
        }else{
            alert.error(res.data.msg)
        }
      }

      const onAddVideo=async(e)=>{
        e.preventDefault()
setLoading(true)
        const data={
            cor_Id,
            title,
            sml_desc,
            video_url
        }
        const res=await axios.post(`${apilink}/api/purchescourse/updateNewYoutubecourse`,data,{
            headers: {
                Authorization: token,
              },
        })
        // console.log(res.data)
        if(res.data.success)
        {
       
            setTimeout(() => {
                alert.success(res.data.msg)
                setLoading(false)
                setPopup(false)
            }, 1500);
            
        }else{
            setTimeout(() => {
                alert.error(res.data.msg)
                setLoading(false)
                setPopup(false)

            }, 1500);
            
        }
      }
      const updateSeenVideo=async(id)=>{
        const res=await axios.get(`${apilink}/api/purchescourse/updateSeenVideo/${id}`,{
          headers: {
            Authorization: token,
          },
        })
        console.log(res.data)
        if(res.data.success)
        {
          let ar=cor_List.map((v)=>{
            if(v._id==id)
            {
              return {...v , 'cor_isActive':!v.cor_isActive}
            }else{
              return v;
            }
          })
            setCor_List(ar)
            alert.success(res.data.msg)
        }else{
            alert.error(res.data.msg)
        }
      }
  return (
    <>
    {
    popup &&  <div className="model_box">
    <div className="inner_model">
      <div className="cross" onClick={()=>setPopup(false)}>&times;</div>
      <div>
       <div className="text-center">
        <h5>Upload Course Video</h5>
       
       </div>
       <form className="my-4" onSubmit={onAddVideo}>
        <div className="form-group ">
                       <label htmlFor=""> Video Title</label>
                       <input
                         type="text"
                         placeholder="Enter Video Title"
                         className="form-control form_h"
                         name="title"
                         value={title}
                         onChange={(e) => setTitle(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group ">
                       <label htmlFor=""> Video Url</label>
                       <input
                         type="text"
                         placeholder="Enter Video Url"
                         className="form-control form_h"
                         name="video_url"
                         value={video_url}
                         onChange={(e) => setVideo_url(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group ">
                       <label htmlFor="">Video Description</label>
                       
                          <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Enter Video Description"
                            name="sml_desc"
                            value={sml_desc}
                            onChange={(e) => setSml_desc(e.target.value)}
                            required
                          ></textarea>
                      
                       
                     </div>
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                      {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                    </div>
        </form>
      </div>
    </div>
  </div>
   }

    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          <div className="container-fluid">
             <div className="dd_flex">
             <h3 className="text_custom">Video Courses</h3>
          <div className="text-right">
        <button className='btn btn-primary' onClick={()=>his.push("/add-purchase-course")}>Add Course</button>
    </div>
            </div>

           {
            cor_List?.length > 0 ?(
                <>
                 <div className="gallery_flex mt-5">
           {
            cor_List?.map((val,ind)=>{
                return(
                    <>
                    
                    <div className="youtube_flex_item " key={ind} >
                            <img src={val.cor_image} className='cur' alt="" onClick={()=>his.push(`/purchase-course/${val._id}/allvideo`)} />
                           <div className="p-2">
                           <h4 className=''>{val.cor_name} </h4>
                           <div className="dd_flex">
                            <NavLink to={`/purchase-course/${val._id}/allvideo`} className='sm_btn'  >All Video</NavLink>
                            <div >
                          {
                            val.cor_isActive ?   <i className='fa fa-eye mr-4 text-custom cur' onClick={()=>updateSeenVideo(val._id)}></i> :   <i className='fa fa-eye-slash mr-4 text-danger cur' onClick={()=>updateSeenVideo(val._id)}></i>
                          }


                                <i onClick={()=>{
                                setPopup(true)
                                setCor_Id(val._id)
                            }} className='fa fa-plus mr-4 text-custom cur'></i>

              <NavLink to={`/edit-purchase-course/${val._id}`}><i className='fa fa-edit text-custom '></i></NavLink>
                            </div>
                          

                           </div>
                           </div>
                            <div className="delete_btn" onClick={()=>onDelete(val._id)}>
                                <i className='fa fa-trash ' ></i>       
                            </div>

                        </div>
                    </>
                )
            })
           }
            </div>
                
                </>
            ):(
                <>

                <div className="p-5 text-center">
                    <h5>No Item Found</h5>
                </div>
                
                </>
            )
           }
          

          </div>
         
           
          </div>
        </div>
      </div>

      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    </>
  )
}

export default PurchesCourse