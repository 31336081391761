import React, { useEffect, useState } from 'react'


import { apilink, path } from '../../data/fdata'
import './Dashboard.css'
import SideBar from '../../component/sidebar/SideBar'
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Avatar from 'react-avatar';
import StuList from '../../component/studentcompo/StuList'
import OrderList from '../../component/studentcompo/OrderList'
import CourseList from '../../component/studentcompo/CourseList'
import axios from  'axios'
import Cookies from 'js-cookie';

const Dashboard = () => {
 

  const token = Cookies.get('_edushark_admin_access_token');
  const his = useHistory();
  const [pageloading, setPageLoading] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [userData, setUserData] = useState([]);

  const [collegeList,setCollegeList] = useState([])
  const [courseList,setCourseList] = useState([])
  const [applicationList,setApplicationList] = useState([])

  const [orderCount,setOrderCount]=useState("")



  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            if(res.data.userInfo?.isAdmin)
            {
              setUserData(res.data.userInfo)
            }else{
              window.location.href = '/login';
            }

          }
          setTimeout(() => {
            setPageLoading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);


  const getcollegedata=async()=>{

    const res=await axios.get(`${apilink}/api/college/getColleges`)
    // console.log(res.data)
  
    if(res.data.success)
    {
      setCollegeList(res.data.result)
    }else{
      alert.error(res.data.msg)
    }
  
  }
  
  const getcoursedata=async()=>{
  
    const res=await axios.get(`${apilink}/api/course/getcourses`)
    // console.log(res.data)
  
    if(res.data.success)
    {
      setCourseList(res.data.result)
    }else{
      alert.error(res.data.msg)
    }
  
  }

  const allApplications=async()=>{
    const res=await axios.get(`${apilink}/api/application/allApplications`)
    if(res.data.success)
    {
      setApplicationList(res.data.result)
    }else{
      alert.error(res.data.msg)
    }
  }

  const totalOrders=async()=>{
    const res=await axios.get(`${apilink}/api/payment/totalOrders`)
    // console.log(res.data)

    if(res.data.success)
    {
      setOrderCount(res.data.result)
    }else{
      alert.error(res.data.msg)
    }

  }

  useEffect(()=>{
    getcollegedata()
    getcoursedata()
    allApplications()
    totalOrders()
  },[])
  return (
    <>
    
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          <div className="welcome_dash">
                <div className="p-3  left_stu_dash">
                <h3>Hi , {userData?.name && userData.name.split(" ")[0]}</h3>
                <h5>Welcome to Edushark</h5>
                </div>
                <div className="right_stu_dash">
                  <img src={`${path}/image/student_study.svg`} alt="" />
                </div>
              </div>
           <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                <div className="card p-3  ">
                  <div className="cardflex">
                  <img src={`${path}/image/reading.png`} alt="" className='card_img' />
                  <div className=" pl-2">
                    <h5>Total Students</h5>
                    <h1 className='text-custom'>{applicationList.length}</h1>

                  </div>
                  </div>
                  
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                <div className="card p-3  ">
                  <div className="cardflex">
                  <img src={`${path}/image/university.png`} alt="" className='card_img' />
                  <div className=" pl-2">
                    <h5>Total Colleges</h5>
                    <h1 className='text-custom'>{collegeList.length}</h1>

                  </div>
                  </div>
                  
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                <div className="card p-3  ">
                  <div className="cardflex">
                  <img src={`${path}/image/webinar.png`} alt="" className='card_img' />
                  <div className=" pl-2">
                    <h5>Total Courses</h5>
                    <h1 className='text-custom'>{courseList.length}</h1>

                  </div>
                  </div>
                  
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                <div className="card p-3  ">
                  <div className="cardflex">
                  <img src={`${path}/image/checkout.png`} alt="" className='card_img' />
                  <div className=" pl-2">
                    <h5>Total Orders</h5>
                    <h1 className='text-custom'>{orderCount}</h1>

                  </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="row mt-5">
            <div className="col-xl-8 col-lg-7 col-md-12 mb-3">
              {
                applicationList.length > 0 &&  <StuList applicationList={applicationList} />
              }
            
             </div>
              <div className="col-xl-4 col-lg-5 col-md-12 mb-3">
          <CourseList courseList={courseList} />
              </div>

            </div>
            <div className="row mt-3">
              <div className="col-12">
             {/* <OrderList/> */}
              </div>
             
            </div>
           </div>
          </div>
        </div>
      </div>
      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    </>
  )
}

export default Dashboard