import React, { useEffect, useState } from 'react'

import SideBar from '../../component/sidebar/SideBar';
import ReactPaginate from 'react-paginate';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAlert } from 'react-alert';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import OrderBookCompo from '../../component/studentcompo/OrderBookCompo';
import { CircularProgress } from "@mui/material";
import { saveAs } from 'file-saver';
import { useRef } from 'react';


const UserDetailsById = () => {
    const [pageLoading, setPageLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [somethingWrong, setSomethingWrong] = useState(false);

    const [applicationId, setApplicationId] = useState({});
    const [orderData, setOrderData] = useState([]);
    const [myData_list, setMyData_list] = useState([]);
    const [orderState, setOrderState] = useState(1);


    const [pageNo, setPageNo] = useState(0);

    const [filter, setFilter] = useState("all");
    const [apiToken, setApiToken] = useState("");

    const tableContainerRef = useRef(null);

  const scrollLeft = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollBy({ left: -250, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollBy({ left: 250, behavior: 'smooth' });
    }
  };

     

    const token = Cookies.get('_edushark_admin_access_token');
  const his = useHistory();
 const alert= useAlert()
 const {userId}= useParams()


 const perpage = 15;
  const pagevisit = pageNo * perpage;

  const dataall = orderData.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(orderData.length / perpage);

  // 

  const pageChange = ({ selected }) => {
    setPageNo(selected);
  };

  const [payData, setPayData] = useState([]);
  const [payData_list, setPayData_list] = useState([]);


  const [pageNoP, setPageNoP] = useState(0);

  const [filterP, setFilterP] = useState("all");

  const perpageP = 15;
  const pagevisitP = pageNoP * perpageP;

  const dataallP = payData.slice(pagevisitP, pagevisitP + perpageP);
  const boxnoP = Math.ceil(payData.length / perpageP);

  const [updateStatus, setUpdateStatus] = useState("");
  const [loading,setLoading] =useState(false)
  const [popupcont, setPopupcont] = useState(false);
  const [activeid, setActiveid] = useState("");

  
  const pageChangeP = ({ selected }) => {
    setPageNoP(selected);
  };


  useEffect(()=>{

    if(filter=="all"){
      
      setOrderData(myData_list);
    } else{
      setOrderData(myData_list.filter(v=>v.order_status == filter));
    }

  },[filter])

  useEffect(()=>{

    if(filterP=="all"){
      
      setPayData(payData_list);
    } else{
      setPayData(payData_list.filter(v=>v.paymentfee_status == filterP));
    }

  },[filterP])

  const  getAllPaymentFeesById=async(id)=>{
    const res=await axios.get(`${apilink}/api/paymentfees/getAllPaymentFeesById/${id}`)
    console.log(res.data)
    if(res.data.success)
    {
      setPayData(res.data.result)
    setPayData_list(res.data.result)

    }else{
      alert.error(res.data.msg)
    }
  }

 useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            if(!res.data.userInfo?.isAdmin)
            {
              window.location.href = '/login';
            }
  
          }
          setTimeout(() => {
            setPageLoading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);

  const getUserById=async(id)=>{
       
            const res=await axios.get(`${apilink}/api/user/getUserById/${id}`);
           if(res.data.success) {
            setUserData(res.data.result)
           }else{
            alert.error(res.data.msg)
            setSomethingWrong(true)
           }
        
  }

  const getApplicationsByUserId=async(id)=>{
    const res=await axios.get(`${apilink}/api/application/getApplicationsByUserId/${id}`);
    if(res.data.success) {
      setApplicationId(res.data.result)
    }else{
     alert.error(res.data.msg)
     setSomethingWrong(true)
    }
  }

  const getOrderByUserId=async()=>{
    const res=await axios.get(`${apilink}/api/orderbook/getOrderByUserId/${userId}`)
    // console.log(res.data)
    if(res.data.success) {
      setOrderData(res.data.result)
    setMyData_list(res.data.result)

    }else{
     alert.error(res.data.msg)
     setSomethingWrong(true)
    }

  }

  const getToken=async()=>{
    const res = await axios.get(`${apilink}/api/shiprocket/loginUser`);
    // console.log(res.data)
    if (res.data.success) {
      setApiToken(res.data.result.token);
    }else{
      alert.error(res.data.msg)
    }
  }

  useEffect(()=>{
    if(userId)
        {
            getUserById(userId)
            getApplicationsByUserId(userId)
            getAllPaymentFeesById(userId)
            getOrderByUserId()
            getToken()
        }
  },[userId])


  const generatepdf=async(pid,table)=>{
    setPageLoading(true)
  
    const res=await axios.post(`${apilink}/api/pdf/generate-pdf`,{
      pid,
      table
    })
    // console.log(res.data)
    if(res.data.success)
    {
     alert.success(res.data.msg)
     const ress=await axios.get(`${apilink}/api/pdf/fetch-pdf/${pid}`,{ responseType: 'blob' })
         if(ress)
         {
             const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });
  
               saveAs(pdfBlob, `invoice_pdf_${pid}.pdf`);
               const re=await axios.get(`${apilink}/api/pdf/delete-pdf/${pid}`)
               if(!re.data.success)
               {
                 his.push("something-wrong")
               }
         }
  
    }else{
     alert.error(res.data.msg)
    }
    setPageLoading(false)
  }

  const onUpdateStatuscontent=async(e)=>{
    e.preventDefault();
    setLoading(true)

    const data={
      activeid,
      updateStatus
    }

    const res=await axios.post(`${apilink}/api/paymentfees/updatePaymentFee`,data,{
      headers: {
        Authorization: token,
      },
    })

    if(res.data.success)
    {
      getAllPaymentFeesById(userId)


      setTimeout(() => {
        setActiveid("")
        setUpdateStatus("")

        setLoading(false)
        alert.success(res.data.msg)
        setPopupcont(false)
      }, 1500);

    }else{

      setTimeout(() => {
        setActiveid("")
        setUpdateStatus("")
        setLoading(false)
        alert.error(res.data.msg)
        setPopupcont(false)
      }, 1500);

    }

    
  }


  return (
    <>

{
    popupcont &&  <div className="model_box">
    <div className="inner_model">
      <div className="cross" onClick={()=>{
        setPopupcont(false)
        setActiveid("")
        setUpdateStatus("")

      }}>&times;</div>
      <div>
       <div className="text-center">
        <h5>Update Status</h5>
       
       </div>
       <form className="my-4" onSubmit={onUpdateStatuscontent}>
       <div className="form-group ">
                   
                       <select
                          class="form-control form_h"
                          value={updateStatus}
                          onChange={(e) => {
                            setUpdateStatus(e.target.value)
                            
                          }}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Status--
                          </option>
                          <option value="PENDING">Pending</option>
                          <option value="APPROVED">Approved</option>
                          <option value="REJECTED">Rejected</option>

                       
                        </select>
                       
                     </div>
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                      {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                    </div>
        </form>
      </div>
    </div>
  </div>
   }
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            {
                somethingWrong ?(
                    <>
                    <div className="card rounded py-3  px-2 ">
                        <h5 className='text-danger text-center'>Something Went Wrong...</h5>
                    </div>
                    </>
                ):(
                    <>

<div className="container-fluid">
            <div className="card rounded py-3  px-2 mb-3 ">
              <h3 className=""><u>Account Details</u></h3>
             <h5>Name: <span className="text-custom">{userData?.name}</span> </h5>
             <h5>Email: <span className="text-custom">{userData?.email}</span> </h5>
             <h5>Phone: <span className="text-custom">{userData?.phone}</span> </h5>

            {
              applicationId?._id &&  <div className="text-right">
              <NavLink to={`/student/${applicationId?._id}`} className="btn btn-primary">View Application</NavLink>
              </div>
            }
              
             </div>


             <div className="card rounded py-3  px-2 mb-3">
                  <div className="dd_flex">
                  <h5 className='text-custom'>Fees Payments</h5>
                  <div className='dd_flex'>
            <div class="form-group">
              <select class="form-control filterform" value={filterP} onChange={(e)=>setFilterP(e.target.value) }>
               
                <option value="all">All</option>
                <option value="APPROVED">Approved</option>
                <option value="REJECTED">Rejected</option>
                <option value="PENDING">Pending</option>
              </select>
            </div>
           
          </div>
                  </div>

                  

                  {
                  dataallP.length > 0 ?(
                    <>
                     <div class="table-responsive " >
          <table class="table table-borderless dash ">
            <thead>
              <tr>
                {/* <th className="w_20">Id</th> */}
                <th className="w_20">UserName</th>
                <th className="w_20">Phone</th>
                <th className="w_20">Type</th>
                <th className="w_60">FeesType</th>
                <th className="w_60">CollegeName</th>
                <th className="w_20">CourseName</th>

                <th className="w_20">PaymentId</th>
               <th className='w_20'>Amount</th>
               <th className="w_20">PayStatus</th>
                <th className="sm_w">Status</th>
                <th className='sm_w'>Operation</th>
              </tr>
            </thead>
            <tbody className=''>
              {
                dataallP?.map((val,ind)=>{
                  return(
                    <>
                    <tr>
                {/* <td><small>{val._id}</small></td> */}
                <td>{val?.userID?.name}</td>
                <td><a href={`tel:${val?.userID?.phone}`}>{val?.userID?.phone}</a></td>
                <td>
                  {val.pay_type}
                </td>
                <td>
                  {val.feestype}
                </td>
                <td>
                  {val.collegeID?.col_name}
                </td>
                <td>
                  {val.applicationID?.course_name}
                </td>
                <td>
                 {val.payment_id}
                </td>
                <td>
                ₹ {Number(val.feesamount).toFixed(2)}
                </td>
                <td>{val.payment_status  ? <>
                <p className='m-0 text-success'>PAID &nbsp; <span onClick={()=>generatepdf(val._id,val.pay_type)} className='badge badge-light py-1 px-2 cur'><i className='fa fa-download mr-1'></i> Receipt</span></p>
                
                </>: 
                <>
                <p className='m-0 text-danger'>UNPAID/FAILED</p>
                </>
                } </td>
                <td> 

                {val.paymentfee_status=="PENDING" &&  <span className='badge badge-warning'>Pending</span>}
                  {val.paymentfee_status=="APPROVED" &&  <span className='badge badge-success'>Approved</span>}
                  {val.paymentfee_status=="REJECTED" &&  <span className='badge badge-danger'>Rejected</span>}


                </td>
                <td>
                  <button className='sm_btn' onClick={()=>{
                    setPopupcont(true)
                    setActiveid(val._id)
                  }}>Update</button>

                </td>
              </tr>
                    </>
                  )
                })
              }
             
            </tbody>
          </table>
        </div>
                    
                    </>
                  ):(
                    <>
                    <div className="py-4 text-center">
                      <h5>No Item Found</h5>
                    </div>
                    </>
                  )
                 }

        <div className="row pt-4">
         <div className="col-12">
         {
          dataallP.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxnoP}
          onPageChange={pageChangeP}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>

                  </div>



            
             {
                    orderState == 1 ?(
                      <>
                      <button className='sml_btn active_btn m-1' onClick={()=>{
                        setOrderState(1)
                        
                      }}>Purches Classes</button>
                  <button className='sml_btn m-1' onClick={()=>{
                    setOrderState(2)
                    setFilter('all')
                    
                  }}>Purches Books <span className='bg-warning p-1 rounded'>{myData_list.length}</span></button>
                      </>
                    ):(
                      <>
                      
                      
                      <button className='sml_btn  m-1' onClick={()=>{
                        setOrderState(1)
                        
                      }}>Purches Classes</button>
                  <button className='sml_btn active_btn m-1' onClick={()=>{
                    setOrderState(2)
                    setFilter('all')
                    

                  }}>Purches Books <span className='bg-warning p-1 rounded'>{myData_list.length}</span></button>
                      </>
                    )
                  }



                  {
                    orderState == 1 ?(
                      <>
                       <div className="card py-5 px-3 mt-2">
                      <div className="text-center">
                      <button className='btn btn-primary px-3 '  onClick={() => {
                             
                             window.open("https://class.edushark.in", "_blank")
                           }}> Live Classes Orders</button>
                      </div>
                      </div>
                      
                      
                      </>
                    ):(
                      <>
                      
                      <div className="card p-2 mt-2">
                  <div className="dd_flex">
                  <h5 className='text-custom'>Orders</h5>
                  <div className='dd_flex'>
            
            <div class="form-group">
              <select class="form-control filterform" value={filter} onChange={(e)=>setFilter(e.target.value) }>
               
              <option  value="all">
                   All
                  </option>
                <option value="APPROVED">Approved</option>
                <option value="REJECTED">Rejected</option>
                <option value="PENDING">Pending</option>
              </select>
            </div>
           
          </div>
                  </div>
                  <div>
         <button className='sml_btn m-1' onClick={scrollLeft}><i class="fa fa-long-arrow-left" aria-hidden="true"></i>
</button>
      <button className='sml_btn m-1' onClick={scrollRight}><i class="fa fa-long-arrow-right" aria-hidden="true"></i>
</button>
         </div>

                 {
                  dataall.length > 0 ?(
                    <>
                     <div class="table-responsive " ref={tableContainerRef}>
          <table class="table table-borderless dash  ">
            <thead>
              <tr>
                <th className="w_20">OrderId</th>
                <th className="w_20">UserName</th>
                <th className="w_20">Phone</th>
                <th className="w_60">ProductDetails</th>
                <th className="w_20">PaymentId</th>
                <th className='w_20'>Amount</th>
                <th className="w_20">PayStatus</th>
                <th className="sm_w">SHIP_Status</th>
                <th className="sm_w">Status</th>

                <th className='sm_w'>Operation</th>
              </tr>
            </thead>
            <tbody className=''>
              {
                dataall?.map((val,ind)=>{
                  return(
                    <>
                    <OrderBookCompo key={ind} orderbook={val} alert={alert}  apitoken={apiToken} getAllBookOrders={getOrderByUserId} setPageLoading={setPageLoading} />
                    </>
                  )
                })
              }
             
            </tbody>
          </table>
        </div>
                    
                    </>
                  ):(
                    <>
                    <div className="py-4 text-center">
                      <h5>No Item Found</h5>
                    </div>
                    </>
                  )
                 }

        <div className="row pt-4">
         <div className="col-12">
         {
          dataall.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxno}
          onPageChange={pageChange}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>

                  </div>
                      </>
                    )
                  }
               
                </div>
            
                    
                    
                    </>
                )
            }

           
         
           
          </div>
        </div>
      </div>
      {
        pageLoading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    
    </>
  )
}

export default UserDetailsById