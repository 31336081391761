import React, { useEffect, useState } from 'react';
import 'swiper/swiper.min.css';

import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

import Error from './pages/Error';

import './App.css';
import NavBar from './component/navbar/NavBar';
import Dashboard from './pages/dashboard/Dashboard';
import Home from './pages/home/Home';
import AddCollege from './pages/college/AddCollege';
import AddCourses from './pages/course/AddCourses';
import Student from './pages/student/Student';
import Payment from './pages/payment/Payment';
import Setting from './pages/account/Setting';
import Login from './pages/auth/Login';
import ForgetPassword from './pages/auth/ForgetPassword';
import ResetPassword from './pages/auth/ResetPassword';
import StudentDetails from './pages/student/StudentDetails';
import Courses from './pages/course/Courses';
import Order from './pages/order/Order';
import Account from './pages/account/Account';
import EditCourse from './pages/course/EditCourse';
import Contact from './pages/contact/Contact';
import Gallery from './pages/gallery/Gallery';
import EditCollege from './pages/college/EditCollege';
import PurchesCourse from './pages/purches/PurchesCourse';
import AddPurchesCourse from './pages/purches/AddPurchesCourse';
import EditPurchesCourse from './pages/purches/EditPurchesCourse';
import VideoCourseDetails from './pages/purches/VideoCourseDetails';
import PurchesBook from './pages/purches/PurchesBook';
import AddPurchesBooks from './pages/purches/AddPurchesBooks';
import EditPurchesBook from './pages/purches/EditPurchesBook';
import Users from './pages/users/Users';
import FeesOption from './pages/feesoption/FeesOption';
import UserDetailsById from './pages/users/UserDetailsById';



const App = () => {
  const loc = useLocation();
 

 

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [loc]);



  return (
    <>

        <NavBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/college" component={AddCollege} />
          <Route exact path="/edit-college/:colid" component={EditCollege} />
          <Route exact path="/course" component={Courses} />
          <Route exact path="/add-course" component={AddCourses} />
          <Route exact path="/student" component={Student} />
          <Route exact path="/student/:appid" component={StudentDetails} />
          <Route exact path="/orders" component={Order} />
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/setting" component={Setting} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route exact path="/reset-password/:accesstoken" component={ResetPassword} />
          <Route exact path="/edit-course/:cid" component={EditCourse} />
          <Route exact path="/contacts" component={Contact} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/purchase-course" component={PurchesCourse}/>
          <Route exact path="/purchase-course/:cid/allvideo" component={VideoCourseDetails}/>
          <Route exact path="/add-purchase-course" component={ AddPurchesCourse}/>
          <Route exact path="/edit-purchase-course/:cid" component={ EditPurchesCourse}/>

          <Route exact path="/purchase-book" component={PurchesBook}/>
          <Route exact path="/users" component={Users}/>
          <Route exact path="/user/:userId" component={UserDetailsById}/>

          <Route exact path="/fees-options" component={FeesOption}/>

          
     

          <Route exact path="/add-purchase-book" component={ AddPurchesBooks}/>
          <Route exact path="/edit-purchase-book/:bookid" component={ EditPurchesBook}/>



        

          
          
          <Route component={Error} />
        </Switch>
        
      
        
      
    </>
  );
};

export default App;
