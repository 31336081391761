import React from 'react'
import './PurchesCourse.css'
import { useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';
import { CircularProgress } from "@mui/material";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import axios from 'axios'
import SideBar from '../../component/sidebar/SideBar';
import { apilink } from '../../data/fdata';
import { useEffect } from 'react';

const AddPurchesCourse = () => {
    const token = Cookies.get('_edushark_admin_access_token');
    const his = useHistory();
     const alert= useAlert()

    const [cor_name, setCorName] = useState('');
  const [cor_image, setCorImage] = useState([]);
  const [cor_price, setCorPrice] = useState('');
  const [cor_duration, setCorDuration] = useState('');
  const [cor_link, setCorLink] = useState('');

  // cor_link
 
  const [cor_description, setCorDescription] = useState('');
 const [loading, setLoading] = useState(false);


  
  
  useEffect(async() => {
    if(token) {
      
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            if(!res.data.userInfo?.isAdmin)
            {
              window.location.href = '/login';
            }
  
          }
          
         
    }else{
      his.push("/login")
    }
  }, []);

 const onSubmitForm=async(e)=>{
    e.preventDefault();
    setLoading(true)

    let formData = new FormData();
    formData.append("file", cor_image);
  
    const res = await axios.post(`${apilink}/api/file/upload`, formData, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: token,
      },
    });
    if(res.data.success)
    {
      const data={
        cor_name,
        cor_price,
       cor_duration,      
       cor_description,       
       cor_image:res.data.imageUrls[0],
       cor_link
    
      }
      const ress=await axios.post(`${apilink}/api/purchescourse/postCourse`,data,{
        headers: {
          
          Authorization: token,
        },
      })
      if(ress.data.success)
      {
        
        alert.success("Course added successfully")
        setTimeout(() => {
          his.push("/purchase-course")
        }, 1500);
      }else{
        alert.error(ress.data.msg)
  
      }
    
      
  
    }else{
      alert.error(res.data.msg)
    }
  
   
  
  
    setLoading(false)

 }

 const handelFile = (e) => {
    const { files } = e.target;
    // console.log(files[0]); files[0].type === 'application/pdf'
    if (files.length > 0) {
      if (files[0].size > 2000000) {
        alert.error(` File should be less then 2 MB`);
       
      } else if (
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
       
        setCorImage(files[0]);
       
      } else {
        
        alert.error(`File extension should be only jpg, png , jpeg`);
      }
     
    }
  };
  
  return (
    <>
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          <div className="container-fluid">
          <h3 className="text_custom">Add Live Courses</h3>
            <div className="row">
              <div className="col-12">
                <form className='mt-5' onSubmit={onSubmitForm}>

               
                   <div class="form-row">
                     <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Course Title</label>
                       <input
                         type="text"
                         placeholder="Enter Course Title"
                         className="form-control form_h"
                         name="cor_name"
                         value={cor_name}
                         onChange={(e) => setCorName(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-6 col-md-6">
                       <label htmlFor=""> Course Price</label>
                       <input
                         type="text"
                         placeholder="Enter Course Price"
                         className="form-control form_h"
                         name="cor_price"
                         value={cor_price}
                         onChange={(e) => setCorPrice(e.target.value)}
                         required
                       />
                     </div>
                   </div>
                   <div class="form-row">
                     <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Upload Course Image:</label>
                       <input
                         type="file"                         
                         className="form-control form_h"
                         name="image"
                         accept=".png, .jpeg , .jpg "
                          onChange={handelFile}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-6 col-md-6">
                       <label htmlFor=""> Course Duration</label>
                       <input
                         type="text"
                         placeholder="Enter Course Duration"
                         className="form-control form_h"
                         name="cor_duration"
                         value={cor_duration}
                         onChange={(e) => setCorDuration(e.target.value)}
                         required
                       />
                     </div>
                     
                   </div>
                   <div className="form-row">
                   <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Course Link:</label>
                       <input
                         type="text"
                         placeholder="Enter Course Link"
                         className="form-control form_h"
                         name="cor_link"
                         value={cor_link}
                         onChange={(e) => setCorLink(e.target.value)}
                         required
                       />
                     </div>
                   <div className="form-group col-lg-6 col-md-6">
                       <label htmlFor="">Course Description</label>
                       
                          <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Enter Course Description"
                            name="cor_description"
                            value={cor_description}
                            onChange={(e) => setCorDescription(e.target.value)}
                            required
                          ></textarea>
                      
                       
                     </div>
                   </div>
                   

                   <div className="text-right">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                   
                </form>
                
              </div>
            </div>

          </div>
         
           
          </div>
        </div>
      </div>
    </>
  )
}

export default AddPurchesCourse