import React, { useEffect, useState } from 'react'
import './Order.css'
import SideBar from '../../component/sidebar/SideBar'
import ReactPaginate from 'react-paginate';
import { NavLink , useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { apilink } from '../../data/fdata';
import Cookies from 'js-cookie';
import axios from 'axios';
import { CircularProgress } from "@mui/material";
import { useAlert } from "react-alert";
import OrderBookCompo from '../../component/studentcompo/OrderBookCompo';
import { useRef } from 'react';


const Order = () => {
  const token = Cookies.get('_edushark_admin_access_token');
  const his = useHistory();
  const alert=useAlert()

  const [pageloading, setPageLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [myDatac, setMyDatac] = useState([]);
  const [myDatac_list, setMyDatac_list] = useState([]);
  const [myData_list, setMyData_list] = useState([]);


  const [updateStatus, setUpdateStatus] = useState("");
  const [loading,setLoading] =useState(false)
  const [popupcont, setPopupcont] = useState(false);
  const [activeid, setActiveid] = useState("");


  const tableContainerRef = useRef(null);

  const scrollLeft = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollBy({ left: -250, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollBy({ left: 250, behavior: 'smooth' });
    }
  };


  const [pageNo, setPageNo] = useState(0);


  const [filterc, setFilterc] = useState("all");

  const [filter, setFilter] = useState("all");
  const [filterpayment, setFilterpayment] = useState("all");




  const [orderState, setOrderState] = useState(1);
  const [apiToken, setApiToken] = useState("");


  const perpage = 15;
  const pagevisit = pageNo * perpage;

  const dataall = myData.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(myData.length / perpage);

  const dataallc = myDatac.slice(pagevisit, pagevisit + perpage);
  const boxnoc = Math.ceil(myDatac.length / perpage);

  const pageChange = ({ selected }) => {
    setPageNo(selected);
  };


  
  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            if(res.data.userInfo?.isAdmin)
            {
              
            }else{
              window.location.href = '/login';
            }

          }
          setTimeout(() => {
            setPageLoading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);



  useEffect(()=>{

    // if(filter=="all"){
      
    //   setMyData(myData_list);
    // } else{
    //   setMyData(myData_list.filter(v=>v.order_status == filter));
    // }
    let filteredData=myData_list
    if (filterpayment != "all") {
      // console.log(filterpayment)
      filteredData = filteredData.filter(item => item.payment_status == (filterpayment=="PAID") );
    }

    if (filter != "all") {

      filteredData = filteredData.filter(v=>v.order_status == filter);
     
    }
    setMyData(filteredData)

  },[filter , filterpayment])



// useEffect(()=>{
//   let filteredData=myData_list

//     filteredData = filteredData.filter(item => item.payment_status == (filterpayment=="PAID") );
//    setMyData(filteredData)

//   },[])


  useEffect(()=>{

    if(filterc=="all"){
      
      setMyDatac(myDatac_list);
    } else{
      setMyDatac(myDatac_list.filter(v=>v.study_status==filterc));
    }

  },[filterc])



  const allOrderstudyContent=async()=>{
    const res=await axios.get(`${apilink}/api/orderstudycontent/allOrderstudyContent`)
  //  console.log(res.data)
   if(res.data.success)
   {
    setMyDatac(res.data.result)
    setMyDatac_list(res.data.result)
   }else{
    alert.error(res.data.msg)
   }
  }
  const  getAllBookOrders=async()=>{
    const res=await axios.get(`${apilink}/api/orderbook/getAllBookOrders`)
    // console.log(res.data)
    if(res.data.success)
    {
      setMyData(res.data.result)
    setMyData_list(res.data.result)

    }else{
      alert.error(res.data.msg)
    }
  }

  const getToken=async()=>{
    const res = await axios.get(`${apilink}/api/shiprocket/loginUser`);
    // console.log(res.data)
    if (res.data.success) {
      setApiToken(res.data.result.token);
    }else{
      alert.error(res.data.msg)
    }
  }

  useEffect(()=>{
    getToken()
    allOrderstudyContent()
    getAllBookOrders()
  },[])

  const onUpdateStatuscontent=async(e)=>{
    e.preventDefault();
    setLoading(true)

    const data={
      activeid,
      updateStatus
    }

    const res=await axios.post(`${apilink}/api/orderstudycontent/updateOrderstudyContent`,data,{
      headers: {
        Authorization: token,
      },
    })

    if(res.data.success)
    {
      allOrderstudyContent()


      setTimeout(() => {
        setActiveid("")
        setUpdateStatus("")

        setLoading(false)
        alert.success(res.data.msg)
        setPopupcont(false)
      }, 1500);

    }else{

      setTimeout(() => {
        setActiveid("")
        setUpdateStatus("")
        setLoading(false)
        alert.error(res.data.msg)
        setPopupcont(false)
      }, 1500);

    }

    
  }
  return (
    <>

{
    popupcont &&  <div className="model_box">
    <div className="inner_model">
      <div className="cross" onClick={()=>{
        setPopupcont(false)
        setActiveid("")
        setUpdateStatus("")

      }}>&times;</div>
      <div>
       <div className="text-center">
        <h5>Update Status</h5>
       
       </div>
       <form className="my-4" onSubmit={onUpdateStatuscontent}>
       <div className="form-group ">
                   
                       <select
                          class="form-control form_h"
                          value={updateStatus}
                          onChange={(e) => {
                            setUpdateStatus(e.target.value)
                            
                          }}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Status--
                          </option>
                          <option value="PENDING">Pending</option>
                          <option value="APPROVED">Approved</option>
                          <option value="REJECTED">Rejected</option>

                       
                        </select>
                       
                     </div>
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                      {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                    </div>
        </form>
      </div>
    </div>
  </div>
   }
    
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              
              <div className="row">
                <div className="col-12 mb-2">
                {/* <button className='sml_btn active_btn '>Purches Books</button> */}
                  {
                    orderState == 1 ?(
                      <>
                      <button className='sml_btn active_btn m-1' onClick={()=>{
                        setOrderState(1)
                        setFilterc('all')
                      }}>Purches Classes</button>
                  <button className='sml_btn m-1' onClick={()=>{
                    setOrderState(2)
                    setFilter('all')
                    setFilterpayment("PAID")
                    
                  }}>Purches Books <span className='bg-warning p-1 rounded'>{myData_list.length}</span></button>
                      </>
                    ):(
                      <>
                      
                      
                      <button className='sml_btn  m-1' onClick={()=>{
                        setOrderState(1)
                        setFilterc('all')
                      }}>Purches Classes</button>
                  <button className='sml_btn active_btn m-1' onClick={()=>{
                    setOrderState(2)
                    setFilter('all')
                    

                  }}>Purches Books <span className='bg-warning p-1 rounded'>{myData_list.length}</span></button>
                      </>
                    )
                  }

                </div>
               {
                orderState == 1 ?(
                  <>
                   <div className="col-12">
                <div className="card py-5 px-3">
                  <div className="text-center">
                  <button className='btn btn-primary px-3 '  onClick={() => {
                         
                         window.open("https://class.edushark.in", "_blank")
                       }}> Live Classes Orders</button>
                  </div>
                  </div>
                </div>
                  
                  
                  </>
                ):(
                  <>

<div className="col-12">
                <div className="card p-2">
                  <div className="dd_flex">
                  <h5 className='text-custom'>Orders</h5>
                  <div className='dd_flex'>

                  <div class="form-group mr-2">
              <select class="form-control filterform" value={filterpayment} onChange={(e)=>setFilterpayment(e.target.value) }>
               
              <option  value="all">
                   All
                  </option>
                <option value="PAID">PAID</option>
                <option value="UNPAID">UNPAID</option>
              
              </select>
            </div>
            
            
            <div class="form-group ">
              <select class="form-control filterform" value={filter} onChange={(e)=>setFilter(e.target.value) }>
               
              <option  value="all">
                   All
                  </option>
                <option value="APPROVED">Approved</option>
                <option value="REJECTED">Rejected</option>
                <option value="PENDING">Pending</option>
              </select>
            </div>

            <button className="sml_btn mt5 bg-primary ml-1" onClick={()=>{
                  setFilter("all")
                  setFilterpayment("all")
                  getAllBookOrders()
                }}> <i className=" fa fa-refresh "></i></button>


           
          </div>
                  </div>

                  <div>
         <button className='sml_btn m-1' onClick={scrollLeft}><i class="fa fa-long-arrow-left" aria-hidden="true"></i>
</button>
      <button className='sml_btn m-1' onClick={scrollRight}><i class="fa fa-long-arrow-right" aria-hidden="true"></i>
</button>
         </div>

                 {
                  dataall.length > 0 ?(
                    <>
                     <div class="table-responsive "  ref={tableContainerRef}>
          <table class="table table-borderless dash  ">
            <thead>
              <tr>
              <th className="w_20">Date</th>

                <th className="w_20">OrderId</th>
                <th className="w_20">UserName</th>
                <th className="w_20">Phone</th>
                <th className="w_60">ProductDetails</th>
                <th className="w_20">PaymentId</th>
                <th className='w_20'>Amount</th>
                <th className="w_20">PayStatus</th>
                <th className="sm_w">SHIP_Status</th>
                <th className="sm_w">Status</th>

                <th className='w_20'>Operation</th>
              </tr>
            </thead>
            <tbody className=''>
              {
                dataall?.map((val,ind)=>{
                  return(
                    <>
                    <OrderBookCompo setFilterpayment ={ setFilterpayment} key={ind} orderbook={val} alert={alert}  apitoken={apiToken} getAllBookOrders={getAllBookOrders} setPageLoading={setPageLoading} />
                    </>
                  )
                })
              }
             
            </tbody>
          </table>
        </div>
                    
                    </>
                  ):(
                    <>
                    <div className="py-4 text-center">
                      <h5>No Item Found</h5>
                    </div>
                    </>
                  )
                 }

        <div className="row pt-4">
         <div className="col-12">
         {
          dataall.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxno}
          onPageChange={pageChange}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>

                  </div>
                </div>
                  
                  
                  </>
                )
               }
              </div>
            </div>
         
           
          </div>
        </div>
      </div>
      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    </>
  )
}

export default Order