import React, { useEffect, useState } from 'react'
import './Account.css'
import SideBar from '../../component/sidebar/SideBar'
import { CircularProgress } from '@mui/material'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Cookies from 'js-cookie'
import { apilink } from '../../data/fdata'
import axios from 'axios'

const Setting = () => {


  const alert = useAlert();
  const [pageloading, setPageLoading] = useState(false);
  const token = Cookies.get('_edushark_admin_access_token');
  
  const [password, setPassword] = useState('');
  const [npassword, setNPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  
  const [loading, setLoading] = useState(false);
  
  const his = useHistory();

  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            if(!res.data.userInfo?.isAdmin)
            {
              window.location.href = '/login';
            }
  
          }
          setTimeout(() => {
            setPageLoading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);

  const onUpdatePassword=async(e)=>{
    e.preventDefault()
    setLoading(true)

    if(npassword.length <6)
    {
      alert.error("Password length must be at least 6")
    }else if(npassword != cpassword)
    {
      alert.error("Password not match")
    }else{
      const res=await axios.post(`${apilink}/api/admin/updateadminPassword`,{
        password,
        npassword
      },{
        headers: {
          Authorization: token,
        },
      })

      console.log(res.data)

        if(res.data.success)
        {
          alert.success(res.data.msg)
          setTimeout(() => {

            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
            
          }, 1500);

          

        }else{
          alert.error(res.data.msg)
        }

    }


    setLoading(false)
  }

  return (
    <>
    
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-8 col-12">
                  <div className="card p-3">
                    <h4>Update Password</h4>
                    <form onSubmit={onUpdatePassword} className='mt-4'>
                    <div class="form-group">
                    <input
                      type="password"
                      placeholder="Enter Old Password"
                      class="form-control"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      placeholder="Enter New Password"
                      class="form-control"
                      name="npassword"
                      value={npassword}
                      onChange={(e) => setNPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      class="form-control"
                      name="cpassword"
                      value={cpassword}
                      onChange={(e) => setCPassword(e.target.value)}
                      required
                    />
                  </div>

                 
                  <div className="text-center">
                    <button
                      type="submit"
                      className={
                        loading ? 'dis sm_btn' : 'sm_btn'
                      }
                      disabled={loading}
                    >
                      Update Now
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress  size={35} />
                    </div>
                  )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
         
           
          </div>
        </div>
      </div>

      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    </>
  )
}

export default Setting